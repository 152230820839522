
@import url('https://fonts.googleapis.com/css?family=Caveat|Gaegu|Gloria+Hallelujah|Indie+Flower|Reenie+Beanie');

@font-face { font-family: Circular; src: url("https://sp-bootstrap.global.ssl.fastly.net/8.2.4/fonts/circular-book.woff2") format("woff2"), url("https://sp-bootstrap.global.ssl.fastly.net/8.2.4/fonts/circular-book.woff") format("woff"); font-style: normal; font-weight: 400; }

:root {
  --bgcolor: #222;
  --fgcolor: #ccf;
  --hdcolor: #ccf;
  --incolor: white;
  --btncolor: #222;
}

#Clock {
  width: 20px;
  height: 20px;
  -webkit-filter: invert(100%);
  filter: invert(100%);
}

.header {
  background-color: #222222;
  padding: 10px;
  border-bottom: 1px solid #ebebeb;
}


.header a {
  color: #919496;
}

.header button {
  padding: 0;
	border-radius: 0px;
	background-color: var(--btncolor);
}

.header img {
  margin: 0;
  padding: 0;
}

a.active {
  color: #007bff;
}

input:focus, select:focus, button:focus, textarea:focus {
    outline: 0;
}
input[type="button"]:focus {
  outline: none;
}


body {
  /*background-image: url("./parchment-bg.jpg");*/
  background-color: var(--bgcolor);
  font-size: 10pt;
  color: var(--hdcolor);
  font-family: Circular, Helvetica, Arial, sans-serif;
}
div.contents {
  padding: 10px;
}
input, textarea, #tasklog tbody{
  font-family: 'Indie Flower', cursive;
  /*font-family: 'Gloria Hallelujah', cursive;*/
}

input {
  background-color: var(--bgcolor);
  color: var(--incolor);
	border: 0px solid rgb(22,23,26);
	border-bottom: 1px solid gray;
	padding: 2px;
}


select {
	background-color: #919496;
  color: #000000;
	border: 1px solid rgb(22,23,26);
	padding: 2px;
}



.pomos {
  width: 4em;
}

.checkbox-round input[type="checkbox"] {
}

table {
  width: 100%
}


h2 {
  margin-top: 10px;
  margin-bottom: 0px;
}


.taskdone {
  width: 1.5em;
  vertical-align: text-bottom;
  padding-top: 5px;
}

.taskcell input[type="text"] {
  width: 100%;
  box-sizing: border-box;
}

.taskctrl {
  width: 50px;
  padding-left: 10px;
  vertical-align: text-bottom;
}

.taskgo {
  width: 2em;
  vertical-align: text-bottom;
}
.taskstop {
  width: 2em;
  vertical-align: text-bottom;
}

.taskgo button {
  background-color: var(--btncolor);
  color: var(--fgcolor);
  margin-bottom: 4px;
}
.taskstop button {
  margin-bottom: 4px;
  border: 1px solid var(--fgcolor);
}

.target {
  padding-left: 10px;
  padding-right: 10px;
}

.task {
  left: 0;
  right: 100px;
}

button .interrupt, .complete {
  margin: 10em;
}

#header {
  float: right;
}


img.profile {
  width: 25px;
  height: 25px;
}

.dayctl {
  padding: 2px;
  padding-left: 5px;
  padding-right: 5px;
  border: 1px solid grey;
  margin-left: 0px;
  margin-right: 2px;
}

.datepickerspan {
  display: inline-block;
}

.datepicker {
  display: inline-block;
  outline: none;
  padding: 2px;
  border: 1px solid var(--bgcolor);
}

div > .react-datepicker-wrapper {
  display: inline-block;
}

.react-datepicker-wrapper input {
  width: 140px;
}

#inspiration {
  margin-left: 0;
}
.taskctrl button {
  width: 100%;
}
#logout {
  float: right;
}

/* task view controls */
.timerbox {
  margin: 0px;
  padding: 10px;
  margin-top: 10px; 
  border: 2px solid var(--bgcolor);
  background-color: #811;
  color: var(--fgcolor);
}

.timer {
}

.taskbuttons button {
  margin-right: 10px;
  margin-top: 20px;
}

.timerbox h2 {
  font-size: 32px; 
}

h2 {
  font-size: 1rem;
}

textarea {
  /*position:absolute;*/
  /*left:0; top:0;*/
  width:100%;
}

.taskchecked {
  text-decoration: line-through;
}


#logo {
}
.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.productivity {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.score-group {
  display: inline-block;
  text-align: center;
  margin: 5px;
}

.score-group label {
  display: block;
}


#description {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

#tasklog {
    border-collapse: collapse;
    width: 100%;
}

#tasklog td, #tasklog th {
    border: 1px solid #ddd;
    padding: 4px;
}
#tasklog td:nth-child(1) {
  var(--fgcolor)-space: nowrap;
}


#tasklog tr:nth-child(even){
  /*background-color: #f2f2f2;*/
}

#tasklog tr:hover {
  /*background-color: #000;*/
}

#tasklog th {
    padding-top: 4px;
    padding-bottom: 4px;
    text-align: left;
    background-color: var(--bgcolor);
    color: var(--fgcolor);
}

input[type='submit'], button {
	padding: 4px 12px 4px 12px;
	color: var(--incolor);
	background-color: var(--bgcolor);
  border: 1px solid black;
	border-radius: 4px;
  font-family: Circular, Helvetica, Arial, sans-serif;
  text-align: center;
  margin: 0px 8px 0px 8px;
}

input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
select:focus,
textarea {
  font-size: 16px;
}
.react-datepicker__input-container > input {
  border-bottom: 0px;
}
input.react-datepicker-ignore-onclickoutside {
  border-bottom: 0px;
}

.taskctrl input {
  border: 1px dashed var(--fgcolor);
  padding-left: 5px;
  padding-right: 5px;
  text-align: center;
  margin-bottom: 8px;
}

.weektable textarea {
  height: 31px;
}
.dayform textarea {
  height: 31px;
}

textarea {
  background-attachment: local;
  background-image:
    linear-gradient(to right, var(--bgcolor) 0px, transparent 0px),
    linear-gradient(to left, var(--bgcolor) 0px, transparent 0px),
    repeating-linear-gradient(var(--bgcolor), var(--bgcolor) 30px, #888 30px, #888 31px, var(--bgcolor) 31px);
  line-height: 31px;
  padding: 0px 0px 0px 0px;
  color: var(--incolor);
  border: 0px;
}
